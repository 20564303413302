import RichText from '@app/_blocks/RichText';
import { Heading } from '@app/_components/ui';
import { useMarket } from '@app/_context';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@ngg/icons';
import type { ProductPageInfoBlockFragment } from '@server/gql/graphql';
import { Fragment } from 'react';

type Props = {
    open: boolean;
    block: ProductPageInfoBlockFragment;
    onClose: () => void;
};

export default function StockDialog({ open, onClose, block }: Props) {
    const { code, locale } = useMarket().state.market;
    return (
        <Transition appear show={open} as={Fragment}>
            <Dialog open={open} onClose={onClose} className="relative z-40">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div
                        className="fixed inset-0 bg-black/30"
                        aria-hidden="true"
                    />
                </Transition.Child>
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Dialog.Panel className="relative mx-auto max-h-full max-w-xl items-start overflow-auto bg-white px-8 py-8 text-left prose-hr:my-6 md:py-12">
                            <button
                                type="button"
                                onClick={onClose}
                                className="absolute right-0 top-2 p-4">
                                <XIcon className="text-base" />
                            </button>
                            <Dialog.Title
                                as={Heading}
                                size={4}
                                className="mb-4">
                                {block.title}
                            </Dialog.Title>
                            <RichText
                                className="prose-dialog"
                                content={block.content}
                                market={code}
                                locale={locale}
                            />
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
