import type { TextblockByNameQuery } from '@server/gql/graphql';
import type { MutableRefObject, ReactNode } from 'react';
import { createContext } from 'react';
import type { Dictionary } from 'types';

import type { MarketAwareSlug } from '@/utils/const';

type CheckoutContextProps = {
    dictionary: Dictionary;
    adyenDropInRef?: MutableRefObject<HTMLDivElement | null>;
    madeToOrderBlock?: TextblockByNameQuery;
    details: MarketAwareSlug;
};

export type CheckoutProviderProps = CheckoutContextProps & {
    children: ReactNode;
};

export const CheckoutContext = createContext<CheckoutContextProps | undefined>(
    undefined,
);
