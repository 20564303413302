import type { CartItem } from '@server/gql/graphql';
import type { ComponentProps } from 'react';
import type { Dictionary } from 'types';

import { cn } from '@/lib/utils';

import { Heading, Paragraph } from '../ui';

export default function StockWatchStatus({
    stock,
    onRemove,
    onUpdate,
    dictionary,
    className,
}: {
    stock: CartItem['stockWatchStatus'];
    onRemove: () => void;
    onUpdate: (quantity: number) => void;
    dictionary: Dictionary;
} & ComponentProps<'div'>) {
    if (!stock) return null;
    const { ableToComplete, currentOnHandValue } = stock;
    if (ableToComplete) return null;
    return (
        <div
            className={cn(
                'grid grid-flow-col grid-cols-[1fr,auto] grid-rows-[auto,auto] items-center gap-x-1 bg-red-50 px-4 py-2 text-left text-sm text-red-600',
                className,
            )}>
            <Heading as="h3">
                {typeof currentOnHandValue === 'number' &&
                currentOnHandValue > 0
                    ? dictionary?.onlyXLeftInStock.replace(
                          '%X%',
                          currentOnHandValue.toString(),
                      )
                    : dictionary?.notInStock}
            </Heading>
            <Paragraph>
                {currentOnHandValue
                    ? dictionary?.updateQuantityToProceed
                    : dictionary?.productIsSoldOut}
            </Paragraph>
            <button
                onClick={() =>
                    currentOnHandValue
                        ? onUpdate(currentOnHandValue)
                        : onRemove()
                }
                className="col-start-2 row-span-2 border border-red-600 px-2 font-sans">
                {currentOnHandValue
                    ? (dictionary?.update ?? 'Uppdatera')
                    : (dictionary?.remove ?? 'Ta bort')}
            </button>
        </div>
    );
}
