import { useMarket } from '@context/marketContext';
import searchProducts from '@server/requests/product/searchProducts';
import getSearchEngineUserUid from '@server/utils/getSearchEngineUserUid';
import type { Filter, Loop54Content } from '@server/utils/parseLoop/types';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useReducer } from 'react';
import type { Sort } from 'types';

import { PRODUCTS_PER_PAGE } from '@/config/products';
import { generateHash, getInitialParams } from '@/utils/productSearch';

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'filter': {
            const newState = {
                ...state,
                page: 0,
                skip: 0,
                filters: action.value,
            };
            if (typeof window !== 'undefined') {
                window.history.replaceState(
                    window.history.state,
                    '',
                    generateHash(newState.sort, newState.filters),
                );
            }
            return newState;
        }
        case 'sort': {
            const newState = {
                ...state,
                page: 0,
                skip: 0,
                sort: action.value,
            };
            if (typeof window !== 'undefined') {
                window.history.replaceState(
                    window.history.state,
                    '',
                    generateHash(newState.sort, newState.filters),
                );
            }
            return newState;
        }
        case 'reset': {
            return {};
        }
        default:
            return state;
    }
};

const createInitialState = ({ pageIndex }: { pageIndex: number }) => {
    const params = getInitialParams();

    return {
        page: pageIndex,
        skip: pageIndex * PRODUCTS_PER_PAGE,
        take: PRODUCTS_PER_PAGE,
        sort: params.sort,
        filters: params.filters,
    };
};

const useSearch = ({
    query,
    initialData,
    disabled,
    pageIndex,
}: {
    query: string;
    initialData?: Loop54Content;
    disabled?: boolean;
    pageIndex: number;
}) => {
    const market = useMarket().state.market;
    const [state, dispatch] = useReducer(
        reducer,
        {
            initialData,
            query,
            pageIndex,
        },
        createInitialState,
    );

    const searchEngineUser = getSearchEngineUserUid();

    const { data, isFetching, isError, error, isLoading, isPlaceholderData } =
        useQuery<Loop54Content>({
            queryKey: [
                'search',
                query,
                state.skip,
                state.take,
                state.sort,
                state.filters,
                market.loop54Market,
            ],
            queryFn: () =>
                searchProducts({
                    query,
                    take: state.take,
                    skip: state.skip,
                    sort: state.sort,
                    filters: state.filters,
                    marketConfig: market,
                    searchEngineUser,
                }),
            initialData,
            staleTime: 5,
            placeholderData: keepPreviousData,
            refetchOnWindowFocus: true,
            enabled: !disabled && state.mounted,
        });

    const updateFilter = (payload: Filter[]) => {
        dispatch({
            type: 'filter',
            value: payload,
        });
    };
    const updateSort = (payload: Sort) => {
        dispatch({
            type: 'sort',
            value: payload,
        });
    };

    return {
        updateFilter,
        page: state.page,
        sort: state.sort,
        updateSort,
        data,
        isFetching,
        isError,
        error,
        isLoading,
        isPreviousData: isPlaceholderData,
    };
};

export default useSearch;
