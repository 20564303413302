'use client';

import { tv } from 'tailwind-variants';

import DeliveryItem from './statuses/DeliveryItem';
import IncomingItem from './statuses/IncomingItem';
import InStockItem from './statuses/InStockItem';
import LimitedStockItem from './statuses/LimitedStockItem';
import OutOfStockItem from './statuses/OutOfStockItem';
import SoldOutItem from './statuses/SoldOutItem';
import Unknown from './statuses/Unknown';
import { useStockStatus } from './Stock.hooks';
import type { StockStatusProps } from './Stock.types';
import { StockStatusEnum } from './Stock.types';

export const stockStatus = tv({
    base: 'mr-2 inline-block h-em w-em shrink-0 rounded-full',
    variants: {
        code: {
            [StockStatusEnum.UNKNOWN]: 'bg-yellow-500',
            [StockStatusEnum.IN_STOCK_ITEM]: 'bg-green-500',
            [StockStatusEnum.DELIVERY_ITEM]: 'bg-green-500',
            [StockStatusEnum.INCOMING_ITEM]: 'bg-green-500',
            [StockStatusEnum.SOLD_OUT_ITEM]: 'hidden bg-red-500',
            [StockStatusEnum.OUT_OF_STOCK_ITEM]: 'hidden bg-red-500',
            [StockStatusEnum.LIMITED_STOCK_ITEM]: 'bg-green-500',
        },
    },
});

export default function Stock(props: StockStatusProps) {
    const status = useStockStatus(props);
    switch (status?.code) {
        case StockStatusEnum.DELIVERY_ITEM: {
            return <DeliveryItem status={status} config={props} />;
        }
        case StockStatusEnum.INCOMING_ITEM: {
            return <IncomingItem status={status} config={props} />;
        }
        case StockStatusEnum.IN_STOCK_ITEM: {
            return <InStockItem status={status} config={props} />;
        }
        case StockStatusEnum.LIMITED_STOCK_ITEM: {
            return <LimitedStockItem status={status} config={props} />;
        }
        case StockStatusEnum.OUT_OF_STOCK_ITEM: {
            return <OutOfStockItem status={status} config={props} />;
        }
        case StockStatusEnum.SOLD_OUT_ITEM: {
            return <SoldOutItem status={status} config={props} />;
        }
        case StockStatusEnum.UNKNOWN: {
            return <Unknown status={status} config={props} />;
        }
        default: {
            return null;
        }
    }
}
