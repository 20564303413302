'use client';

import { Paragraph } from '@app/_components/ui';

import { cn } from '@/lib/utils';

import type { SOLD_OUT_ITEM } from '../Stock.types';
import { type StockStatusProps } from '../Stock.types';
import StockIndicator from './StockIndicator';

export default function SoldOutItem({
    status,
    config,
}: {
    status: SOLD_OUT_ITEM;
    config: StockStatusProps;
}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, item, className, stockInfo, dictionary, ...props } = config;
    if (type === 'pdp') {
        return (
            <div className={cn('text-sm', className)} {...props}>
                <div className="inline-flex flex-wrap items-start gap-2">
                    <StockIndicator status={status} />
                    <span className="inline-block font-bold">
                        {dictionary?.soldOut}
                    </span>
                </div>
                <Paragraph className="mt-2 text-gray-500">
                    {dictionary?.soldOutExplanation}
                </Paragraph>
            </div>
        );
    }
    return (
        <div
            className={cn('inline-flex items-start gap-2 text-xxs', className)}
            {...props}>
            <StockIndicator status={status} />
            <span className="inline-block">{dictionary?.soldOut}</span>
        </div>
    );
}
