import Stock from '../Stock/Stock';
import { useCartItemContext } from './CartItem.context';

type CartItemStockProps = {
    className?: string;
};

const CartItemStock = ({ className }: CartItemStockProps) => {
    const { item, dictionary } = useCartItemContext();

    return (
        <Stock
            item={item}
            dictionary={dictionary}
            type="cart"
            className={className}
        />
    );
};

CartItemStock.displayName = 'CartItemStock';

export default CartItemStock;
