import {
    parseResults,
    PRODUCTS_BY_CATEGORY_ENDPOINT,
} from '@server/parsers/loop54';
import type { Loop54Payload } from '@server/utils/parseLoop/types';
import { cache } from 'react';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import type { MarketConfiguration } from '@/config/markets/const';
import { DEFAULT_CATEGORY_ATTRIBUTE } from '@/utils/const';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

const POPULAR_CATEGORIES = ['NG_MÖBEL', 'NG_BELYSNING', 'NG_UTEMÖBEL'];

const fetchPopular = cache(
    async (marketConfig: MarketConfiguration, take = 8) => {
        const payload: Loop54Payload = {
            attribute: {
                name: DEFAULT_CATEGORY_ATTRIBUTE,
                value: POPULAR_CATEGORIES,
            },
            resultsOptions: {
                take,
                filter: {
                    and: [
                        {
                            type: 'type',
                            value: 'Product',
                        },
                        { attributeName: `${marketConfig.loop54Market}_Price` },
                    ],
                },
                sortBy: [
                    {
                        type: 'popularity',
                        order: 'desc',
                    },
                ],
                skip: 0,
                facets: [],
            },
            customData: {
                market: marketConfig.loop54Market,
            },
        };
        const url = `${marketConfig.searchEngineUrl}/${PRODUCTS_BY_CATEGORY_ENDPOINT}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': getSearchEngineUser(),
            },
            body: JSON.stringify(payload),
            next: {
                tags: [
                    REVALIDATION_TAGS.loopAll,
                    ...POPULAR_CATEGORIES.map(REVALIDATION_TAGS.categoryByCode),
                ],
                revalidate: ONCE_PER_EIGHT_HOURS,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const items = await response.json();

        return parseResults(items);
    },
);

export const preload = (market: MarketConfiguration, take = 8) => {
    void fetchPopular(market, take);
};

export default fetchPopular;
