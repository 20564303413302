import { graphql } from '@server/gql';

export const getCheckoutDeliveryQuery = graphql(/* GraphQL */ `
    query getCheckoutDelivery(
        $cartId: String!
        $zipCode: String!
        $locale: String!
    ) {
        deliveries(cartId: $cartId, locale: $locale, zipCode: $zipCode) {
            deliveryCheckoutId
            currency
            language
            addons {
                id
                description
                selected
                priceDescription
                price
            }
            options {
                ...deliveryOption
            }
        }
    }
`);
