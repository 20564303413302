import { PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import parseProduct from '@server/utils/parseLoop/parseProduct';
import parseVariant from '@server/utils/parseLoop/parseVariant';
import type { Loop54Response } from '@server/utils/parseLoop/types';
import type { Maybe } from 'graphql/jsutils/Maybe';
import { cache } from 'react';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import type { MarketConfiguration } from '@/config/markets/const';
import { setLoopCacheOptions } from '@/utils/cache/setLoopCacheOptions';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

type LoopFilter = {
    type?: string;
    attributeName?: string;
    value?: string;
    and?: LoopFilter[];
    or?: LoopFilter[];
    not?: LoopFilter;
};

type LoopResultsOptions = {
    skip?: number;
    take?: Maybe<number>;
    filter: LoopFilter;
};

type LoopPayload = {
    resultsOptions: LoopResultsOptions;
    customData: {
        market: string;
    };
};

const fetchCuratedProducts = cache(
    async (ids: string[], take: number, marketConfig: MarketConfiguration) => {
        const url = `${marketConfig.searchEngineUrl}/${PRODUCTS_ENDPOINT}`;

        const payload: LoopPayload = {
            customData: { market: marketConfig.loop54Market },
            resultsOptions: {
                take,
                skip: 0,
                filter: {
                    and: [
                        {
                            or: ids
                                .filter(Boolean)
                                .map((value) => ({ type: 'id', value })),
                        },
                        { attributeName: `${marketConfig.loop54Market}_Price` },
                        {
                            type: 'type',
                            value: 'Product',
                        },
                    ],
                },
            },
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': getSearchEngineUser(),
            },
            body: JSON.stringify(payload),
            ...setLoopCacheOptions({
                revalidate: ONCE_PER_EIGHT_HOURS,
                tags: [
                    REVALIDATION_TAGS.loopAll,
                    ...ids.map((id) => REVALIDATION_TAGS.productById(id)),
                ],
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data: Loop54Response = await response.json();

        const products = data.results.items
            .filter((item) => item.type === 'Product')
            .map(parseProduct);

        if (process.env.ENABLE_VARIANTS_IN_CURATED_PRODUCTS === 'true') {
            const variants = data.results.items
                .filter((item) => item.type === 'Variant')
                .map(parseVariant);

            const items = [
                ...new Map(
                    [...variants, ...products].map((item) => [item.id, item]),
                ).values(),
            ];

            return items;
        }

        return products;
    },
);

export const preload = (
    ids: string[],
    take: number,
    market: MarketConfiguration,
) => {
    void fetchCuratedProducts(ids, take, market);
};

export default fetchCuratedProducts;
