export const CART_COOKIE = 'ng_cart';

export const GIFT_CARD_COOKIE = 'ng_gift_card';

export const CLEARANCE_FLAGS = [
    'season-sale',
    '202409-outlet-40',
    '202411-bw-outlet',
    '202412-season-sale-tot',
];

export const FREE_SHIPPING_TRESHOLD = 1000;
