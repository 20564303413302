import { service } from '@server/client';
import type {
    GetWalleyCheckoutFormQuery,
    GetWalleyCheckoutFormQueryVariables,
} from '@server/gql/graphql';
import { getWalleyCheckoutFormQuery } from '@server/queries/checkout';

export const getWalleyCheckoutForm = async (
    variables: GetWalleyCheckoutFormQueryVariables,
) => {
    try {
        const response = await service<
            GetWalleyCheckoutFormQuery,
            GetWalleyCheckoutFormQueryVariables
        >({
            query: getWalleyCheckoutFormQuery,
            variables,
        });

        return response.walleyCheckoutForm;
    } catch (error: Error | unknown) {
        console.error('Error in getWalleyCheckoutForm', error);
        throw error;
    }
};
