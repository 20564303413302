'use client';

import { useMarket } from '@app/_context';
import { useMemo } from 'react';

import type { StockProps } from './Stock.types';
import { getStockStatus } from './Stock.utils';

export function useStockStatus({ item, type }: StockProps) {
    const marketConfig = useMarket().state.market;

    const status = useMemo(() => {
        if (!item) return;
        if (type === 'pdp') {
            return getStockStatus({
                type,
                item,
                locale: marketConfig.serviceLocale,
            });
        }
        if (type === 'plp') {
            return getStockStatus({
                type,
                item,
                locale: marketConfig.serviceLocale,
            });
        }
        if (type === 'cart' || type === 'checkout') {
            return getStockStatus({
                type,
                item,
                locale: marketConfig.serviceLocale,
            });
        }
        return;
    }, [item, type, marketConfig]);

    return status;
}
