import parseProduct from '@server/utils/parseLoop/parseProduct';
import parseVariant from '@server/utils/parseLoop/parseVariant';
import type {
    Filter,
    Loop54Content,
    Loop54Response,
    LoopFetchProductResult,
} from '@server/utils/parseLoop/types';
import type { CommerceData } from 'types/common';
import type { Product, Variant } from 'types/product';

import type { ProductQueries } from '@/utils/product/types';

import { getCurrentVariantAndProductImages } from '../getCurrentVariant';

export const parseResult = (
    response: Loop54Response,
    market?: string,
    filter?: ProductQueries,
): LoopFetchProductResult => {
    const { results } = response;
    const { items: _items } = results || { items: [] };

    const products = _items.filter((item) => item.type === 'Product');
    const _variants = _items.filter((item) => item.type === 'Variant');

    const [product] = products.map(parseProduct);
    const variants = _variants.map(parseVariant);
    const { currentVariant, productImages } = getCurrentVariantAndProductImages(
        product,
        variants,
        filter,
    );

    const attributes = new Map<string, any>();

    if (!product?.variantAttributes?.length)
        return { product, variants, attributes, currentVariant, productImages };
    if (!variants?.length)
        return { product, variants, attributes, currentVariant, productImages };

    for (let i = 0; i < variants.length; i += 1) {
        const attrs = JSON.parse(variants[i].VariantDefiningAttributes);
        const { price, stockStatus, Flags = [], originalPrice } = variants[i];
        const campaign = Flags.includes(`campaign_${market}`);
        const pricematch = Flags.includes(
            `pricematched-priceindx-ng-${market}`,
        );
        let path = '';
        for (let j = 0; j < product.variantAttributes?.length; j += 1) {
            const { key } = product.variantAttributes[j];
            let id = '';
            if (attrs[key]?.Value) id = `${path}${key}=${attrs[key].Value}`;
            else if (attrs[key]?.Values)
                id = `${path}${key}=${attrs[key].Values.map(
                    ({ Value }: any) => Value,
                ).join(',')}`;
            if (attributes.has(id)) {
                const variant = attributes.get(id) as any;
                attributes.set(id, {
                    ...variant,
                    price: [...new Set([...variant.price, price])],
                    stock: [
                        ...new Set([
                            ...variant.stock,
                            stockStatus ?? 'Unavailable',
                        ]),
                    ],
                    campaign: [
                        ...new Set([
                            ...variant.campaign,
                            campaign ?? pricematch ?? false,
                        ]),
                    ],
                    ...(originalPrice !== undefined && {
                        originalPrice: [
                            ...new Set([
                                ...(variant?.originalPrice ?? []),
                                originalPrice,
                            ]),
                        ],
                    }),
                });
            } else {
                attributes.set(id, {
                    attrs,
                    price: [price],
                    stock: [stockStatus ?? 'Unavailable'],
                    campaign: [campaign ?? false],
                    ...(originalPrice !== undefined && {
                        originalPrice: [originalPrice],
                    }),
                });
            }
            path = `${id}&`;
        }
    }
    return { product, variants, attributes, currentVariant, productImages };
};

export const parseLoopResponse = (
    loop54Response: Loop54Response,
    filters: Filter[],
    commerceData?: CommerceData[],
): Loop54Content => {
    const { results, relatedResults } = loop54Response;
    const { facets, items, count } = results;

    const products = items
        .map((item) =>
            item.type === 'Product' ? parseProduct(item) : parseVariant(item),
        )
        .filter((i) => i !== undefined);
    const relatedProducts = relatedResults?.items.map(parseProduct);

    if (!facets?.length) {
        return {
            filter: [],
            items: products,
            relatedItems: relatedProducts,
            count,
        };
    }

    const enrichedFilters: Filter[] = [];

    for (let i = 0; i < facets.length; i += 1) {
        const facet = facets[i];

        const filter = filters.find((f) => f?.name === facet.name);

        if (!filter) {
            break;
        }

        if (filter.type === 'range' && facet.type === 'range') {
            if (facet.min !== undefined && facet.max !== undefined) {
                enrichedFilters.push({
                    show: true,
                    name: facet.name,
                    type: 'range',
                    extras: filter.extras,
                    attribute: filter.attribute,
                    ...(facet.selectedMin !== undefined &&
                    facet.selectedMax !== undefined
                        ? {
                              min: facet.min,
                              max: facet.max,
                              values: [facet.selectedMin, facet.selectedMax],
                          }
                        : {
                              min: facet.min,
                              max: facet.max,
                              values: undefined,
                          }),
                });
            }
        } else if (filter.type === 'checkbox' && facet.type === 'distinct') {
            let mappedFacetItems = facet.items?.map((j) => ({
                name: j.item,
                count: j.count,
            }));

            if (filter.items && filter.items.length > facet.items.length) {
                mappedFacetItems = filter.items?.map((item) => ({
                    name: item.name,
                    count: 0,
                }));

                for (let j = 0; j < facet.items.length; j += 1) {
                    const index = mappedFacetItems.findIndex(
                        (item) => item.name === facet.items[j].item,
                    );

                    if (mappedFacetItems[index]) {
                        mappedFacetItems[index].count = facet.items[j].count;
                    }
                }
            }

            enrichedFilters.push({
                show: true,
                name: facet.name,
                type: 'checkbox',
                extras: filter.extras,
                items: mappedFacetItems,
                values: filter.values ?? [],
                attribute: filter.attribute,
            });
        }
    }

    let sortedProducts: (Product | Variant | undefined)[] = products;

    const [data] = commerceData ?? [];

    if (data) {
        const { commerceDataType, items: originalOrder } = data;

        if (commerceDataType === 'itemNumbers') {
            sortedProducts = originalOrder.map((itemId) =>
                products.find(({ id }: { id: string }) => id === itemId),
            );
        }
    }

    return {
        filter: enrichedFilters,
        items: sortedProducts.filter(Boolean) as Product[],
        relatedItems: relatedProducts,
        count,
    };
};
