import type { MarketConfiguration } from '@/config/markets/const';

type Loop54EventEntityAttribute = {
    name: string;
} & (
    | { type: 'string' | 'date'; values: string[] }
    | { type: 'number' | 'integer'; values: number[] }
    | { type: 'boolean'; values: boolean[] }
);

export type Loop54EventEntityType = 'Product' | 'Variant';

type Loop54EventEntity = {
    type: Loop54EventEntityType;
    id: string;
    attributes?: Loop54EventEntityAttribute;
};

export type Loop54Event = {
    entity: Loop54EventEntity;
} & (
    | {
          type: 'click' | 'addtocart';
          revenue?: never;
          orderId?: never;
          quantity?: never;
      }
    | {
          type: 'purchase';
          revenue: number;
          orderId: string;
          quantity: number;
      }
);

type CreateEventPayload = {
    events: Loop54Event[];
    customData?: any;
};

type CreateLoopEventProps = {
    searchEngineUser: string;
    marketConfig: MarketConfiguration;
    payload: CreateEventPayload;
};

export default async function createLoopEvent({
    payload,
    marketConfig,
    searchEngineUser,
}: CreateLoopEventProps) {
    const url = `${marketConfig.searchEngineUrl}/createEvents`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Api-Version': 'V3',
            'User-Id': searchEngineUser,
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        const msg = await response.text();
        throw new Error(`Failed to create behavior events - ${msg}`);
    }

    return undefined;
}
