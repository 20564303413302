import { z } from 'zod';

import { envConfig } from '@/config/env';

import { getAppLocale } from './getAppLocale';

export function getAppBaseUrl(path?: string, options?: { locale?: string }) {
    const baseUrl = z.string().parse(envConfig.NEXT_PUBLIC_APP_BASE_URL);

    return [baseUrl, getAppLocale(options?.locale), path]
        .filter(Boolean)
        .join('/');
}
