import { useMarket } from '@context/marketContext';
import { useShippingCost } from '@pages/checkout-v2/Checkout.hooks';
import type { Cart as ICart, Payment } from '@server/gql/graphql';
import type { Dictionary } from 'types/common';

import { cn } from '@/lib/utils';
import { formatPrice } from '@/utils/priceUtils';

type Props = {
    cart?: ICart;
    // eslint-disable-next-line react/no-unused-prop-types
    payments?: Payment[] | null | undefined;
    dictionary: Dictionary;
    className?: string;
    showVAT?: boolean;
    showTotal?: boolean;
    showShipping?: boolean;
};

function NewProductSum(props: Props) {
    const {
        cart,
        dictionary,
        className,
        showVAT = true,
        showTotal = true,
        showShipping = true,
    } = props;
    const { locale } = useMarket().state.market;
    const { hasEstimatedShipping, shippingCost } =
        useShippingCost(showShipping);

    if (!cart) {
        return null;
    }

    return (
        <div className={cn('text-base space-y-1', className)}>
            {showTotal && (
                <div className="flex justify-between child:leading-6">
                    <p>{dictionary?.totalSumProducts}</p>
                    <p>{formatPrice(cart.totalGrossAmount, locale)}</p>
                </div>
            )}
            <div className="flex justify-between child:leading-6">
                <p className="font-bold">{dictionary?.amountToPay}</p>
                <p className="font-bold">
                    {formatPrice(
                        hasEstimatedShipping
                            ? cart.totalGrossAmount + shippingCost
                            : cart.totalGrossAmount,
                        locale,
                    )}
                </p>
            </div>
            {showVAT && (
                <div className="flex justify-between child:leading-6">
                    <p>{dictionary?.vat}</p>
                    <p>{formatPrice(cart.totalVat, locale)}</p>
                </div>
            )}
        </div>
    );
}

export default NewProductSum;
