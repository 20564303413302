import type {
    CartItem,
    ProductPageInfoBlockFragment,
} from '@server/gql/graphql';
import type { ComponentProps } from 'react';
import type { Dictionary, Product, Variant } from 'types';

export enum StockStatusEnum {
    IN_STOCK_ITEM = 'IN_STOCK_ITEM',
    LIMITED_STOCK_ITEM = 'LIMITED_STOCK_ITEM',
    DELIVERY_ITEM = 'DELIVERY_ITEM',
    INCOMING_ITEM = 'INCOMING_ITEM',
    OUT_OF_STOCK_ITEM = 'OUT_OF_STOCK_ITEM',
    SOLD_OUT_ITEM = 'SOLD_OUT_ITEM',
    UNKNOWN = 'UNKNOWN',
}

export type StockProps =
    | {
          type: 'pdp';
          item?: Variant;
      }
    | {
          type: 'plp';
          item?: Product | Variant;
      }
    | {
          type: 'cart' | 'checkout';
          item?: CartItem;
      };

export type IN_STOCK_ITEM = {
    code: StockStatusEnum.IN_STOCK_ITEM;
    quantity: number;
    pretty: string;
    date?: string;
};
export type LIMITED_STOCK_ITEM = {
    code: StockStatusEnum.LIMITED_STOCK_ITEM;
    quantity: number;
    pretty: string;
    date?: never;
};
export type DELIVERY_ITEM = {
    code: StockStatusEnum.DELIVERY_ITEM;
    quantity?: never;
    pretty?: never;
    date?: string;
};
export type INCOMING_ITEM = {
    code: StockStatusEnum.INCOMING_ITEM;
    quantity?: number;
    pretty?: string;
    date: string;
};
export type OUT_OF_STOCK_ITEM = {
    code: StockStatusEnum.OUT_OF_STOCK_ITEM;
    quantity?: never;
    pretty?: never;
    date?: never;
};
export type SOLD_OUT_ITEM = {
    code: StockStatusEnum.SOLD_OUT_ITEM;
    quantity?: never;
    pretty?: never;
    date?: never;
};
export type UNKNOWN = {
    code: StockStatusEnum.UNKNOWN;
    quantity?: never;
    pretty?: never;
    date?: never;
};

export type StockStatus =
    | IN_STOCK_ITEM
    | LIMITED_STOCK_ITEM
    | DELIVERY_ITEM
    | INCOMING_ITEM
    | OUT_OF_STOCK_ITEM
    | SOLD_OUT_ITEM
    | UNKNOWN;

export type StockStatusProps = {
    dictionary: Dictionary;
    stockInfo?: ProductPageInfoBlockFragment | null;
} & StockProps &
    ComponentProps<'div'>;
