import { service } from '@server/client';
import type {
    GetAdyenCheckoutFormQuery,
    GetAdyenCheckoutFormQueryVariables,
} from '@server/gql/graphql';
import { getAdyenCheckoutFormQuery } from '@server/queries/checkout';

export const getAdyenCheckoutForm = async (
    variables: GetAdyenCheckoutFormQueryVariables,
) => {
    try {
        const response = await service<
            GetAdyenCheckoutFormQuery,
            GetAdyenCheckoutFormQueryVariables
        >({
            query: getAdyenCheckoutFormQuery,
            variables,
        });

        return response.adyenCheckoutForm;
    } catch (error: Error | unknown) {
        console.error('Error in getAdyenCheckoutForm', error);
        throw error;
    }
};
