import type { z } from 'zod';

import type {
    getAddressSchema,
    getDeliveryAddressSchema,
    nShiftDeliveryOptionSchema,
} from './address/Address.utils';
import type { CheckoutStore } from './Checkout.store';

export enum WalleyPaymentType {
    b2b = 'b2b',
    b2c = 'b2c',
}

export type Address = z.infer<ReturnType<typeof getAddressSchema>>;

export type DeliveryAddress = z.infer<
    ReturnType<typeof getDeliveryAddressSchema>
>;

export type NShiftDeliveryOption = z.infer<typeof nShiftDeliveryOptionSchema>;

export type AddressFormFields = Address & DeliveryAddress;

type CheckoutLocalStorageState = Pick<
    CheckoutStore,
    'address' | 'deliveryAddress' | 'hasSeparateDeliveryAddress'
>;

export type CheckoutLocalStorage = {
    state?: CheckoutLocalStorageState;
};
