import { z } from 'zod';

import { envConfig } from '@/config/env';
import { getMarketConfigFromLocale } from '@/config/market-configurations';
import { getAppBaseUrl } from '@/utils/getAppBaseUrl';

export function getWalleyReturnUrl(locale?: string) {
    const walleyReturnUrl = z
        .string({
            message: 'Walley return url is required',
        })
        .parse(envConfig.NEXT_PUBLIC_WALLEY_RETURN_URL);

    const url = getAppBaseUrl(walleyReturnUrl, {
        locale,
    });

    return url;
}

export function getWalleyTermsUrl(locale?: string) {
    const marketConfig = getMarketConfigFromLocale(locale);

    return getAppBaseUrl(marketConfig.termsSlug, {
        locale,
    });
}
