import { cache } from 'react';
import type { Product, Variant } from 'types/product';

import type { MarketConfiguration } from '@/config/markets/const';
import type { ProductQueries } from '@/utils/product/types';

import fetchRelation from './fetchRelation';

type ComparableSelector<T extends any[]> = T extends (infer U)[]
    ? keyof U
    : never;

const lowestItem = (
    items: Variant[],
    comparable: ComparableSelector<Variant[]>,
) =>
    items?.reduce(
        (prev, curr) => (prev[comparable] <= curr[comparable] ? prev : curr),
        items?.[0],
    );

const getLightsource = (
    lightsources: (Variant & { isRecommended: boolean })[],
    params?: ProductQueries,
) => {
    if (typeof params?.lightsource === 'string') {
        if (params?.lightsource === 'no-lightsource') {
            return undefined;
        }

        return lightsources.find((ls) => ls?.id === params.lightsource);
    }

    const recommended = lightsources?.filter((ls) => ls?.isRecommended);

    if (!recommended.length) {
        return lowestItem(lightsources, 'price');
    }

    return lowestItem(recommended, 'price');
};

export type Lightsources = {
    lightsources?: Variant[];
    lightsource?: Variant;
};

export const fetchLightsources = cache(
    async (
        product: Product,
        marketConfig: MarketConfiguration,
        params?: ProductQueries,
    ) => {
        const recommendedIds = product.recommendedLightsources ?? [];
        const relatedIds = product.relatedLightsources ?? [];
        const ids = [...new Set([...recommendedIds, ...relatedIds])];

        if (!ids.length) {
            return {
                lightsource: undefined,
                lightsources: [],
            };
        }

        const fetchedLightsources = await fetchRelation(
            ids.slice(0, 16),
            marketConfig,
        );

        const lightsources = fetchedLightsources.map((lightsource) => ({
            ...lightsource,
            isRecommended: recommendedIds.includes(lightsource.id),
        }));

        const lightsource = getLightsource(lightsources, params);

        return {
            lightsources,
            lightsource,
        };
    },
);

export default fetchLightsources;
