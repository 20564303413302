import { Check } from 'lucide-react';
import { tv } from 'tailwind-variants';

import { type StockStatus, StockStatusEnum } from '../Stock.types';

export const stockStatusVariant = tv({
    base: 'inline-flex h-3 w-3 shrink-0 items-center justify-center self-center rounded-full',
    variants: {
        code: {
            [StockStatusEnum.UNKNOWN]: 'bg-yellow-500',
            [StockStatusEnum.IN_STOCK_ITEM]: 'bg-green-500 text-white',
            [StockStatusEnum.DELIVERY_ITEM]: 'bg-green-500 text-transparent',
            [StockStatusEnum.INCOMING_ITEM]: 'bg-green-500 text-white',
            [StockStatusEnum.SOLD_OUT_ITEM]:
                'hidden bg-red-500 text-transparent',
            [StockStatusEnum.OUT_OF_STOCK_ITEM]:
                'hidden bg-red-500 text-transparent',
            [StockStatusEnum.LIMITED_STOCK_ITEM]: 'bg-green-500 text-white',
        },
    },
});

export default function StockIndicator({ status }: { status: StockStatus }) {
    return (
        <div role="status" className={stockStatusVariant(status)}>
            <Check strokeWidth={4} className="h-2 w-2 text-inherit" />
        </div>
    );
}
