import { useCartState, useMarket } from '@app/_context';
import {
    getCartAddress,
    getCheckout,
    getWalleyCheckoutForm,
    updateCheckoutMethod,
} from '@server/requests/checkout';
import { getAdyenCheckoutForm } from '@server/requests/checkout/getAdyenCheckoutForm';
import { getCheckoutPayments } from '@server/requests/checkout/getCheckoutPayments';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import {
    ADYEN_PAYMENT_METHOD_ID,
    isAdyenCheckoutEnabled,
    isWalleyCheckoutEnabled,
    WALLEY_PAYMENT_METHOD_ID,
} from '@/config/checkout';
import { BRAND_CODE } from '@/config/common';
import { mapLocaleToAdyenLocale } from '@/config/market-configurations';
import { getCartCookie } from '@/utils/cart/getCartCookie';
import getShippingCost from '@/utils/cart/getShippingCost';

import { CheckoutContext } from './Checkout.context';
import { useCheckoutStore } from './Checkout.store';
import { WalleyPaymentType } from './Checkout.types';
import { useGiftCard } from './giftcard/GiftCard.hooks';
import { getAdyenReturnUrl } from './payment/adyen/Adyen.utils';
import {
    getWalleyReturnUrl,
    getWalleyTermsUrl,
} from './payment/walley/Walley.utils';

/**
 * Fetch the checkout and cart details
 */
export function useCheckout() {
    const marketConfig = useMarket().state.market;
    const cartCookie = getCartCookie();

    return useQuery({
        queryKey: ['checkout', cartCookie?.id],
        queryFn: () =>
            getCheckout({
                locale: marketConfig.serviceLocale,
                cartId: cartCookie?.value ?? '',
            }),
        select: (data) => data.cart,
        retry: false,
        enabled: Boolean(cartCookie?.id),
    });
}
/**
 * Fetch the checkout and cart details
 */
export function useCheckoutPayments() {
    const marketConfig = useMarket().state.market;
    const cartCookie = getCartCookie();

    return useQuery({
        queryKey: ['checkout', 'payments', cartCookie?.id],
        queryFn: () =>
            getCheckoutPayments({
                locale: marketConfig.serviceLocale,
                cartId: cartCookie?.value ?? '',
            }),
        select: (data) => data.payments,
        retry: false,
        enabled: Boolean(cartCookie?.id),
    });
}

/**
 * Get the checkout context
 * @returns CheckoutContext
 */
export function useCheckoutContext() {
    const context = useContext(CheckoutContext);

    if (!context) {
        throw new Error(
            'CheckoutContext must be used within a CheckoutProvider',
        );
    }

    return context;
}

/**
 * Fetch the checkout and cart details
 */
export function useCheckoutMethod(): void {
    const marketConfig = useMarket().state.market;

    useEffect(() => {
        const cartCookie = getCartCookie();
        if (isAdyenCheckoutEnabled({ marketConfig })) {
            updateCheckoutMethod({
                locale: marketConfig.serviceLocale,
                methodId: ADYEN_PAYMENT_METHOD_ID,
                cartId: cartCookie?.value ?? '',
            });
            return;
        }
        if (isWalleyCheckoutEnabled({ marketConfig })) {
            updateCheckoutMethod({
                locale: marketConfig.serviceLocale,
                methodId: WALLEY_PAYMENT_METHOD_ID,
                cartId: cartCookie?.value ?? '',
            });
            return;
        }
        return;
    }, [marketConfig]);
}

/**
 *  Get the current zip code value from the checkout store
 * @returns zipCode - string
 */

export function useCheckoutZipCode() {
    const marketConfig = useMarket().state.market;

    return useCheckoutStore((state) => {
        if (
            isAdyenCheckoutEnabled({
                marketConfig,
            }) === false
        ) {
            return state.address.zipCode;
        }

        if (state.hasSeparateDeliveryAddress) {
            return state.deliveryAddress?.deliveryZipCode;
        }

        return state.address.zipCode;
    });
}

/**
 * Get the current cart summary details
 * @returns { cartTotal, giftCardAmount } - Cart total and gift card amount
 */
export function useCheckoutSummary() {
    const { data, isLoading } = useCheckout();
    const giftCard = useGiftCard();

    const cartTotal = data?.totalGrossAmount ?? 0;
    const cartVAT = data?.totalVat ?? 0;
    const cartShipping = data?.totalFreightInclVat ?? 0;
    const cartSum = cartTotal - cartShipping;
    const cartCost = Math.max(0, cartTotal - (giftCard?.amount ?? 0));

    return {
        isLoading,
        cartVAT,
        cartSum,
        cartCost,
        cartTotal,
        cartShipping,
        giftCard,
    };
}
/**
 * Get the current cart summary details
 * @returns { cartTotal, giftCardAmount } - Cart total and gift card amount
 */
export function useCheckoutShipping(shipping = true) {
    const { data: cart } = useCheckout();

    const shippingCost = useMemo(() => {
        if (!shipping) return 0;

        return getShippingCost({
            cart,
        });
    }, [cart, shipping]);

    return {
        hasEstimatedShipping: false,
        shippingCost,
    };
}
export function useCheckoutRefresh(): () => Promise<any> {
    const { data } = useCheckout();
    const marketConfig = useMarket().state.market;
    const paymentType = useCheckoutStore((state) => state.paymentType);
    const deliveryOption = useCheckoutStore((state) => state.deliveryOption);

    const refresh = useCallback(async () => {
        const cartCookie = getCartCookie();
        if (isAdyenCheckoutEnabled({ marketConfig })) {
            return getAdyenCheckoutForm({
                cartId: cartCookie?.value ?? '',
                marketCode: marketConfig.code,
                brandCode: BRAND_CODE,
                localeCode: mapLocaleToAdyenLocale(
                    marketConfig.contentfulLocale,
                ),
                returnURL: getAdyenReturnUrl(marketConfig),
            }).then((data) => {
                // Refresh adyen widget here if needed
                return data;
            });
        }
        if (isWalleyCheckoutEnabled({ marketConfig })) {
            window.walley?.checkout?.api?.suspend?.();
            return getWalleyCheckoutForm({
                cartId: cartCookie?.value ?? '',
                marketCode: marketConfig.code,
                brandCode: BRAND_CODE,
                type: 'desktop',
                shopCallbackUrl: getWalleyReturnUrl(marketConfig.locale),
                shopTermsUrl: getWalleyTermsUrl(marketConfig.locale),
                storeCode:
                    paymentType === WalleyPaymentType.b2b
                        ? marketConfig.walleyB2BStoreId
                        : marketConfig.walleyB2CStoreId,
            }).then((data) => {
                window.walley?.checkout?.api?.resume?.();
                return data;
            });
        }
        return Promise.resolve();
    }, [marketConfig, paymentType]);

    useEffect(() => {
        if (!data?.totalGrossAmount) return;
        refresh();
    }, [refresh, deliveryOption, data?.totalGrossAmount]);

    return refresh;
}

/**
 * Get the cart address
 * This is used to get the address from the cart before the checkout address has been updated on the order
 * @todo Rename this hook to useAdyenCheckoutCartAddress, or probably a beter idea - rename market.adyenMarket to market.checkoutMarketCode
 * since useCartAddress is currently a general checkout hook.
 */
export function useCartAddress() {
    const cartCookie = getCartCookie();
    const { market } = useMarket().state;

    return useQuery({
        queryKey: ['cartAddress', cartCookie?.id],
        queryFn: () =>
            getCartAddress({
                cartId: cartCookie?.value ?? '',
                marketCode: market.adyenMarket,
                brandCode: BRAND_CODE,
            }),
        enabled: Boolean(cartCookie?.id),
    });
}

/**
 * Get the current cart shipping cost
 * @returns { hasEstimatedShipping, shippingCost } - Shipping cost and whether the shipping cost is estimated
 */
export function useShippingCost(shipping = true) {
    const { cart } = useCartState();

    const shippingCost = useMemo(() => {
        if (!shipping) return 0;

        return getShippingCost({
            cart,
        });
    }, [cart, shipping]);

    return {
        hasEstimatedShipping: false,
        shippingCost,
    };
}
