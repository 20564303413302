import type { ComponentProps } from 'react';

export default function WalleyIcon(props: ComponentProps<'svg'>) {
    return (
        <svg
            width="51"
            height="12"
            viewBox="0 0 51 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g fill="currentColor">
                <path d="M14.3995 2.09839V6.99439L9.47287 4.51553C9.26207 4.40925 9.00367 4.40925 8.79287 4.51553L3.86627 6.99439V2.09839H2.27847H0.000466766C-0.0165332 2.09839 -0.0233328 2.12239 -0.00973282 2.13267L2.27847 3.4801V8.36925C2.27847 8.8321 2.84627 9.12353 3.30187 8.89382L9.13287 5.95896L14.9639 8.89382C15.4195 9.12353 15.9873 8.8321 15.9873 8.36925V2.09839H14.3995Z" />
                <path d="M26.0001 5.56811V8.96926H24.3478V8.12926C23.066 8.72926 21.6618 9.16126 20.0536 9.16126C18.051 9.16126 16.9392 8.46869 16.9392 6.91554C16.9392 5.16011 18.8058 4.59097 20.8968 4.59097C22.1106 4.59097 23.304 4.79668 24.3478 5.08811C24.3036 3.62411 22.5492 3.43211 21.5598 3.43211C20.5942 3.43211 19.4688 3.54526 17.9728 4.01154L17.49 2.75326C18.8738 2.34526 20.21 2.09497 21.774 2.09497C24.5586 2.0984 25.9763 2.97268 26.0001 5.56811ZM24.3478 6.9944V6.32583C23.4366 6.07554 22.369 5.85954 21.043 5.85954C19.5912 5.85954 18.7038 6.14411 18.7038 6.86754C18.7038 7.64926 19.411 7.80697 20.5704 7.80697C21.5904 7.8104 23.2326 7.39211 24.3478 6.9944Z" />
                <path d="M27.0032 -0.0170898H28.642V8.97262H27.0032V-0.0170898Z" />
                <path d="M29.7028 -0.0170898H31.3416V8.97262H29.7028V-0.0170898Z" />
                <path d="M39.522 6.69606H41.239C40.8378 8.23549 39.3214 9.14749 36.8802 9.14749C34.1398 9.14749 32.2426 8.01263 32.2426 5.62977C32.2426 3.24692 34.2146 2.11206 36.7782 2.11206C39.3758 2.11206 41.3376 3.1132 41.3376 5.98977H33.9834C34.1398 7.27206 35.1632 7.77606 36.9142 7.77606C38.5088 7.77606 39.2092 7.3372 39.522 6.69606ZM34.0718 4.82063H39.522C39.233 3.8332 38.2946 3.40463 36.7816 3.40463C35.3536 3.40463 34.3846 3.81949 34.0718 4.82063Z" />
                <path d="M49.1575 2.11206H51.0207L47.0359 9.4252C46.1621 11.0641 45.3019 12.0103 43.5407 12.0103C42.6669 12.0103 41.9563 11.8938 41.2219 11.7429V10.2926C42.0957 10.4915 42.7349 10.5498 43.2381 10.5498C44.2275 10.5498 44.7409 10.2583 45.3699 9.0652L45.4175 8.97263H44.5097L40.7833 2.11206H42.6703L45.9071 8.07777L49.1575 2.11206Z" />
            </g>
        </svg>
    );
}
