import type {
    ApolloError,
    ApolloQueryResult,
    FetchResult,
    MutationFunctionOptions,
} from '@apollo/client';
import StockWatchStatus from '@app/_components/CheckoutItem/StockWatchStatus';
import WalleyIcon from '@app/_icons/WalleyIcon/WalleyIcon';
import CartItem from '@components/CartItem';
import ProductSum from '@components/Checkout/ProductSum';
import Link from '@components/Link';
import { useMarket } from '@context/marketContext';
import { Button } from '@ngg/components';
import { AmericanExpressIcon, MastercardIcon, VisaIcon } from '@ngg/icons';
import { Cart } from '@ngg/ui';
import type {
    AddGiftCardMutation,
    Cart as ICart,
    CartItem as Item,
    CartMetadataInput,
    GetCartQuery,
    GetCheckoutDeliveryQuery,
    GiftCardRemoveResponse,
    GiftCardReserveResponse,
    RemoveGiftCardMutation,
    SetDeliveryOptionMutation,
} from '@server/gql/graphql';
import { useCartQuery } from '@server/hooks';
import type { SetDeliveryOptionPayload } from '@server/hooks/useCartQuery';
import { usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { Dictionary } from 'types';
import type { AddItemPayload } from 'types/cart';

import { isWalleyCheckoutEnabled } from '@/config/checkout';
import { envConfig } from '@/config/env';

interface CartActionProps {
    addItem: (items: AddItemPayload) => Promise<any>;
    showCart: () => void;
    hideCart: () => void;
    updateItem: (item: { item: Item; quantity: number }) => Promise<any>;
    removeItem: (item: Item) => Promise<any>;
    addPromotion: (discount: string) => Promise<any>;
    clearPromotions: () => Promise<any>;
    updateCartMetadata: (metadata: CartMetadataInput) => Promise<any>;
    resetCart: () => void;
    getDeliveryCheckout: (
        zipCode: string,
    ) => Promise<GetCheckoutDeliveryQuery['deliveries']>;
    setDeliveryOption: (
        payload: SetDeliveryOptionPayload,
    ) => Promise<
        FetchResult<
            SetDeliveryOptionMutation,
            Record<string, any>,
            Record<string, any>
        >
    >;
    refetchCart: (
        variables?:
            | Partial<{
                  token: string;
                  cartId: number;
                  marketCode: string;
              }>
            | undefined,
    ) => Promise<ApolloQueryResult<GetCartQuery>>;
    addGiftCard: (
        options: MutationFunctionOptions<AddGiftCardMutation>,
    ) => Promise<GiftCardReserveResponse>;
    removeGiftCard: (
        options: MutationFunctionOptions<RemoveGiftCardMutation>,
    ) => Promise<GiftCardRemoveResponse>;
    setReservingGiftCard: (value: boolean) => void;
}

interface CartStateProps {
    cart?: ICart;
    empty: boolean;
    isError: boolean;
    called: boolean;
    error: {
        getCart?: ApolloError;
        addToCart?: ApolloError;
        createCart?: ApolloError;
        addPromotion?: ApolloError;
        clearPromotion?: ApolloError;
        updateCart?: ApolloError;
        removeFromCart?: ApolloError;
        clearCart?: ApolloError;
        updateCartMetadata?: ApolloError;
        updateDeliveryOption?: ApolloError;
        giftCard?: ApolloError;
    };
    isLoading: boolean;
    loading: {
        getCart: boolean;
        addToCart: boolean;
        createCart: boolean;
        addPromotion: boolean;
        clearPromotion: boolean;
        updateCart: boolean;
        removeFromCart: boolean;
        clearCart: boolean;
        updateCartMetadata: boolean;
        updateDeliveryOption: boolean;
    };
    cartTotal: number;
}

export const CartStateContext = createContext<CartStateProps | null>(null);
export const CartActionContext = createContext<CartActionProps | null>(null);

export const CartProvider = ({
    dictionary,
    children,
}: {
    dictionary: Dictionary;
    children: ReactNode;
}) => {
    const marketConfig = useMarket().state.market;
    const pathname = usePathname();
    const [open, setOpen] = useState<boolean>(false);
    const {
        cart,
        empty,
        error,
        called,
        loading,
        isError,
        isLoading,
        addItem,
        updateItem,
        freezeCheckout,
        removeItem,
        addPromotion,
        clearPromotions,
        updateCartMetadata,
        resetCart,
        getDeliveryCheckout,
        setDeliveryOption,
        refetchCart,
        addGiftCard,
        removeGiftCard,
    } = useCartQuery();

    const showCart = () => setOpen(true);
    const hideCart = () => setOpen(false);

    const actions = useMemo(
        () => ({
            addItem,
            updateItem,
            freezeCheckout,
            removeItem,
            showCart,
            hideCart,
            addPromotion,
            clearPromotions,
            updateCartMetadata,
            resetCart,
            getDeliveryCheckout,
            setDeliveryOption,
            refetchCart,
            addGiftCard,
            removeGiftCard,
        }),
        [
            addItem,
            updateItem,
            freezeCheckout,
            removeItem,
            addPromotion,
            clearPromotions,
            updateCartMetadata,
            resetCart,
            getDeliveryCheckout,
            setDeliveryOption,
            refetchCart,
            addGiftCard,
            removeGiftCard,
        ],
    );

    const cartTotal = useMemo(
        () => (cart?.totalGrossAmount ?? 0) - (cart?.totalFreightInclVat ?? 0),
        [cart?.totalGrossAmount, cart?.totalFreightInclVat],
    );

    const state = useMemo(
        () => ({
            cart,
            empty,
            error,
            called,
            isError,
            loading,
            isLoading,
            cartTotal,
        }),
        [cart, empty, error, called, isError, loading, isLoading, cartTotal],
    );

    useEffect(() => setOpen(false), [pathname]);

    return (
        <CartStateContext.Provider value={state}>
            {/** @ts-expect-error #TODO fix me*/}
            <CartActionContext.Provider value={actions}>
                <Cart
                    open={open}
                    dictionary={dictionary}
                    isEmpty={(cart?.items ?? []).length === 0}
                    classNames={{
                        panel: 'max-md:max-w-xs text-left',
                    }}
                    EmptyCart={
                        <div className="flex flex-col items-start justify-center gap-8 px-3 py-4">
                            <p className="">{dictionary?.emptyCart}</p>
                            <button
                                onClick={hideCart}
                                type="button"
                                className="w-full bg-black px-12 py-3 text-sm tracking-wide text-white">
                                {dictionary?.startShopping}
                            </button>
                        </div>
                    }
                    CartFooter={
                        <div className="justify-self-end border-t border-grey-150 p-3 pb-7">
                            <ProductSum
                                showTotal={false}
                                showShipping={false}
                                cart={cart}
                                className="mb-2"
                                dictionary={dictionary}
                                showVAT={false}
                            />
                            <Button
                                data-testid="Cart-button-goToCheckout"
                                as={Link}
                                locale={marketConfig.locale}
                                className="btn-primary flex w-full items-center justify-center"
                                href="/checkout">
                                <p>{dictionary?.goToCheckout}</p>
                            </Button>
                            <div className="m-auto mt-4 flex items-center justify-center gap-4">
                                {isWalleyCheckoutEnabled({ marketConfig }) && (
                                    <WalleyIcon />
                                )}
                                <MastercardIcon />
                                <VisaIcon />
                                <AmericanExpressIcon />
                            </div>
                        </div>
                    }
                    hideCart={hideCart}>
                    {cart?.items
                        ?.filter(
                            ({ partNo }) =>
                                partNo !==
                                `${envConfig.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
                        )
                        ?.map((item) => (
                                <CartItem.Root
                                    key={item.id}
                                    item={item}
                                    locale={marketConfig.locale}
                                    dictionary={dictionary}
                                    removeItem={removeItem}
                                    updateItem={updateItem}
                                    loading={loading.updateCart}>
                                    <CartItem.Remove />
                                    <CartItem.Image />
                                    <CartItem.Details>
                                        <CartItem.Name className="col-span-2" />
                                        <CartItem.Items />
                                        <div className="flex flex-col gap-y-1.5">
                                            <CartItem.Stock />
                                            <CartItem.Quantity className="col-start-1 mt-auto" />
                                        </div>
                                        <CartItem.Price className="col-start-2 mt-auto text-right" />
                                        <StockWatchStatus
                                            stock={item.stockWatchStatus}
                                            dictionary={dictionary}
                                            onRemove={() => removeItem(item)}
                                            onUpdate={(quantity: number) =>
                                                updateItem({ item, quantity })
                                            }
                                            className="col-span-full"
                                        />
                                    </CartItem.Details>
                                </CartItem.Root>
                            ))
                        .reverse()}
                </Cart>
                {children}
            </CartActionContext.Provider>
        </CartStateContext.Provider>
    );
};

export const useCartState = () => {
    const context = useContext(CartStateContext);
    if (context === undefined)
        throw new Error('useCart must be used within a CartProvider');
    return context as CartStateProps;
};

export const useCartActions = () => {
    const context = useContext(CartActionContext);
    if (context === undefined)
        throw new Error('useCartActions must be used within a CartProvider');
    return context as CartActionProps;
};
