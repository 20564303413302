import type { CoreConfiguration, PaymentCompletedData } from '@adyen/adyen-web';
import { AdyenCheckout } from '@adyen/adyen-web';
import type { AdyenCheckoutForm } from '@server/gql/graphql';
import { z } from 'zod';

import { envConfig } from '@/config/env';
import { mapLocaleToAdyenLocale } from '@/config/market-configurations';
import { getAppBaseUrl } from '@/utils/getAppBaseUrl';

/**
 * Default Adyen configuration
 */
export function getAdyenConfig({
    locale,
    countryCode,
}: {
    locale: string;
    countryCode?: string;
}) {
    const environment = getAdyenEnvironment();
    const clientKey = getAdyenClientKey();

    const config = {
        environment,
        locale,
        countryCode: countryCode?.toUpperCase(),
        clientKey,
        analytics: {
            enabled: true,
        },
    } satisfies CoreConfiguration;

    return config;
}

/**
 * Adyen checkout instance
 */
export function createAdyenCheckoutInstance(
    {
        id,
        sessionData,
        locale,
        countryCode,
    }: {
        id: string;
        sessionData?: string;
        locale: string;
        countryCode?: string;
    },
    adyenConfiguration?: Partial<CoreConfiguration>,
) {
    const defaultConfig = getAdyenConfig({
        locale: mapLocaleToAdyenLocale(locale),
        countryCode,
    });

    const config = {
        ...defaultConfig,
        ...adyenConfiguration,
        session: {
            id,
            sessionData: sessionData ?? undefined,
        },
    } satisfies CoreConfiguration;

    return AdyenCheckout(config);
}

/**
 * Adyen environment to use
 * @url https://docs.adyen.com/online-payments/build-your-integration/sessions-flow/?platform=Web&integration=Drop-in&version=6.0.3#test-and-go-live
 */
export function getAdyenEnvironment() {
    const environmentSchema = z.enum(['test', 'live']);
    const adyenEnvironment = envConfig.NEXT_PUBLIC_ADYEN_ENVIRONMENT ?? 'test';

    const { data, success, error } =
        environmentSchema.safeParse(adyenEnvironment);

    if (!success) {
        console.error('Adyen environment is not valid', error.flatten());
        return;
    }

    return data;
}

/**
 * Client key used for client-side authentication with Adyen
 * @url https://docs.adyen.com/development-resources/client-side-authentication
 */
export function getAdyenClientKey() {
    return z
        .string({
            message: 'Adyen client key is required',
        })
        .parse(envConfig.NEXT_PUBLIC_ADYEN_CLIENT_KEY);
}

/**
 * Return URL for Adyen to redirect to after payment
 * @url https://docs.adyen.com/online-payments/build-your-integration/sessions-flow/?platform=Web&integration=Drop-in&version=6.0.3#return-url
 */
export function getAdyenReturnUrl({
    locale,
    orderRef,
}: {
    locale?: string;
    orderRef?: string;
}) {
    const adyenReturnUrl = z
        .string({
            message: 'Adyen return url is required',
        })
        .parse(envConfig.NEXT_PUBLIC_ADYEN_RETURN_URL);

    const url = getAppBaseUrl(adyenReturnUrl, {
        locale,
    });

    if (orderRef) {
        return url.replace('{ORDERREF}', orderRef);
    }

    return url;
}

/**
 *  Return URL for Adyen to redirect to after payment is completed
 */
export function getAdyenPaymentCompletedReturnUrl({
    locale,
    paymentCompletedData,
    adyenCheckoutForm,
}: {
    locale: string;
    paymentCompletedData: PaymentCompletedData;
    adyenCheckoutForm: AdyenCheckoutForm;
}) {
    const returnUrlSearchParams = new URLSearchParams();
    const returnUrlWithOrderRef = getAdyenReturnUrl({
        locale,
        orderRef: adyenCheckoutForm.orderRef,
    });

    if (adyenCheckoutForm.id) {
        returnUrlSearchParams.append('sessionId', adyenCheckoutForm.id);
    }

    if ('sessionResult' in paymentCompletedData) {
        returnUrlSearchParams.append(
            'sessionResult',
            paymentCompletedData.sessionResult,
        );
    }

    const url = `${returnUrlWithOrderRef}&${returnUrlSearchParams.toString()}`;

    return url;
}
