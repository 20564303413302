import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';

import {
    type Address,
    type DeliveryAddress,
    WalleyPaymentType,
} from './Checkout.types';
import type { DeliveryOption } from './delivery/Delivery.utils';

export type CheckoutStore = {
    address: Partial<Address>;
    disabled: boolean;
    paymentType: WalleyPaymentType;
    hasLine2Address: boolean;
    deliveryAddress: Partial<DeliveryAddress>;
    deliveryOption?: DeliveryOption;
    hasSeparateDeliveryAddress: boolean;
    setAddress: (newAddress: Partial<Address>) => Promise<void>;
    setDisabled: (value: boolean) => void;
    setPaymentType: (paymentType: WalleyPaymentType) => void;
    setDeliveryOption: (deliveryOption?: DeliveryOption) => void;
    setDeliveryAddress: (
        newDeliveryAddress: Partial<DeliveryAddress>,
    ) => Promise<void>;
    setHasLine2Address: (hasLine2Address: boolean) => void;
    setHasSeparateDeliveryAddress: (
        hasSeparateDeliveryAddress: boolean,
    ) => void;
};

export const CHECKOUT_STORAGE_KEY = 'ng_checkout';

export const useCheckoutStore = create<CheckoutStore>()(
    devtools(
        subscribeWithSelector(
            persist(
                (set) => ({
                    /**
                     * Set default values for the store
                     */
                    address: {},
                    disabled: false,
                    paymentType: WalleyPaymentType.b2c,
                    deliveryOption: undefined,
                    deliveryAddress: {},
                    hasLine2Address: false,
                    hasSeparateDeliveryAddress: false,
                    /**
                     *
                     * @param newAddress - The new address
                     * @returns - void
                     */
                    setAddress: (newAddress) => {
                        return Promise.resolve(
                            set((state) => {
                                return {
                                    address: {
                                        ...state.address,
                                        ...newAddress,
                                        zipCode: newAddress.zipCode?.trim(),
                                    },
                                };
                            }),
                        );
                    },
                    /**
                    /**
                     *
                     * @param value - The disabled flag
                     * @returns - void
                     */
                    setDisabled: (value) => {
                        if (value) {
                            set({ disabled: true, deliveryOption: undefined });
                            return;
                        }
                        set({
                            disabled: false,
                        });
                    },
                    /**
                     * Set whether the address has a C/O address
                     * @param hasLine2Address - Whether the address has a C/O address
                     * @returns - void
                     */
                    setHasLine2Address: (hasLine2Address: boolean) => {
                        set({ hasLine2Address });
                    },
                    /**
                     *
                     * @param newDeliveryAddress - The new delivery address
                     * @returns - void
                     */
                    setDeliveryAddress: (newDeliveryAddress) => {
                        return Promise.resolve(
                            set((state) => {
                                return {
                                    deliveryAddress: {
                                        ...state.deliveryAddress,
                                        ...newDeliveryAddress,
                                        deliveryZipCode:
                                            newDeliveryAddress.deliveryZipCode?.trim(),
                                    },
                                };
                            }),
                        );
                    },
                    /**
                     *
                     * @param hasSeparateDeliveryAddress - Whether the customer has a separate delivery address
                     * @returns - void
                     */
                    setHasSeparateDeliveryAddress: (
                        hasSeparateDeliveryAddress: boolean,
                    ) => {
                        set({ hasSeparateDeliveryAddress });
                    },
                    /**
                     *
                     * @param deliveryOption - The delivery option selected by the customer in nShift
                     * @returns - void
                     */
                    setDeliveryOption: async (deliveryOption) => {
                        if (!deliveryOption) {
                            set({ deliveryOption: undefined });
                            return;
                        }
                        window.walley?.checkout?.api?.suspend?.();
                        set(() => ({ deliveryOption }));
                    },
                    setPaymentType: (paymentType) => {
                        set(() => ({ paymentType }));
                    },
                }),
                {
                    // Persist parts of the store in local storage
                    name: CHECKOUT_STORAGE_KEY,
                    partialize: (state) => {
                        return Object.fromEntries(
                            Object.entries(state).filter(([key]) => {
                                return [
                                    'address',
                                    'paymentType',
                                    'deliveryOption',
                                    'deliveryAddress',
                                    'hasSeparateDeliveryAddress',
                                ].includes(key);
                            }),
                        );
                    },
                },
            ),
        ),
    ),
);
