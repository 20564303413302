'use client';

import { Paragraph } from '@app/_components/ui';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';

import { cn } from '@/lib/utils';

import type { DELIVERY_ITEM } from '../Stock.types';
import { type StockStatusProps } from '../Stock.types';
import StockDialog from '../StockDialog';
import StockIndicator from './StockIndicator';

export default function DeliveryItem({
    status,
    config,
}: {
    status: DELIVERY_ITEM;
    config: StockStatusProps;
}) {
    const [popup, setPopup] = useState<boolean>(false);
    const { type, item, className, stockInfo, dictionary, ...props } = config;
    if (type === 'pdp') {
        return (
            <>
                <Accordion.Root
                    type="multiple"
                    className={cn('text-sm', className)}>
                    <Accordion.Item value={item?.id ?? '1'} {...props}>
                        <Accordion.Header asChild>
                            <div className="inline-flex flex-wrap items-start gap-2">
                                <StockIndicator status={status} />
                                <span className="inline-block">
                                    <strong className="font-medium">
                                        {dictionary?.availableForOrder}
                                    </strong>
                                    {', '}
                                    {status.date &&
                                        dictionary?.shipsAtTheEarliest?.replace(
                                            '%X%',
                                            status.date,
                                        )}
                                </span>
                                <Accordion.Trigger className="group inline-flex items-center gap-1 whitespace-nowrap underline">
                                    {dictionary?.moreInfo}
                                    <ChevronDown className="h-3 w-3 transition-all group-data-[state=closed]:rotate-0 group-data-[state=open]:rotate-180" />
                                </Accordion.Trigger>
                            </div>
                        </Accordion.Header>
                        <Accordion.Content className="mt-2 overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                            <Paragraph className="text-gray-500">
                                {dictionary?.deliveryItemExplanation}{' '}
                                {stockInfo && (
                                    <button
                                        className="text-black underline"
                                        onClick={() => setPopup(true)}>
                                        {dictionary?.readMore}
                                    </button>
                                )}
                            </Paragraph>
                        </Accordion.Content>
                    </Accordion.Item>
                </Accordion.Root>
                {stockInfo && (
                    <StockDialog
                        open={popup}
                        onClose={() => setPopup(false)}
                        block={stockInfo}
                    />
                )}
            </>
        );
    }
    return (
        <div
            className={cn('inline-flex items-start gap-2 text-xxs', className)}
            {...props}>
            <StockIndicator status={status} />
            <span className="inline-block">{dictionary?.madeToOrder}</span>
        </div>
    );
}
