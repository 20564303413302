import type {
    AddToCartMutation,
    CartItem,
    CreateCartMutation,
} from '@server/gql/graphql';

export const OPTIMISTIC_CART_PROPS:
    | CreateCartMutation['cart']
    | AddToCartMutation['cart'] = {
    __typename: 'Cart',
    id: 0,
    currencyCode: '',
    totalGrossAmount: 0,
    totalVat: 0,
    totalFreight: 0,
    totalFreightInclVat: 0,
    discountCode: null,
    token: '',
    message: null,
    freightClass: null,
    salesArea: '',
    meta: null,
    basketPromotions: [],
    items: [],
    hasDigitalProductsOnly: false,
};

export const OPTIMISTIC_CART_ITEMS: CartItem = {
    __typename: 'CartItem',
    id: 0,
    brand: '',
    name: '',
    imageKey: '',
    isDiscounted: false,
    lineNo: 0,
    price: 0,
    priceListId: 0,
    priceOriginal: 0,
    totalGrossAmount: 0,
    totalVat: 0,
    vatPerItem: 0,
    extras: null,
    stockStatus: null,
    appliedPromotions: null,
    optionalItems: null,
    parentLineNo: null,
    requirementPromotionIdSeed: null,
    underlyingItems: null,
    quantity: 0,
    partNo: '',
};
